/**@jsx jsx*/
import React from "react"
import { jsx } from "@emotion/core"
import { navigate } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Card from "../components/card"
import { TitleTertiary, TitleSecondary } from "../components/titles"
import { smallMT, largeMT, medMT } from "../components/spacing"
import { ButtonSecondary } from "../components/buttons"

const Content = styled.section`
  ${smallMT}
  grid-column-start: 2;
  grid-row-start: 2;
  z-index: 2;
  /* display: flex;
  flex-direction: column; */
`

const Index = () => {
  const handleClick = () => {
    navigate("/")
  }

  return (
    <Layout>
      <Content>
        <TitleSecondary>
          Woops. The page that you're looking for doesn't exist.
        </TitleSecondary>
        <TitleTertiary css={medMT}>
            You've entered a forbidden room. 
        </TitleTertiary>
        <ButtonSecondary css={largeMT} onClick={handleClick}>
          Take me to safety
        </ButtonSecondary>
      </Content>
    </Layout>
  )
}

export default Index
